import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { InView } from 'react-intersection-observer'
import tw from 'twin.macro'
import { global } from '../styles/global'
import { getHtml } from '../utils/data'
import { format } from '../utils/date'
import { HTMLType, LinkType, StyleType } from '../utils/prop-types'
import Heading from './heading'
import Link from './link'
import { typography, listStyle } from './text'

const SideMenu = ({ links, activeIndex, style }) => {
  return (
    <aside css={[tw`flex flex-col`, style]}>
      <div css={[global`typography.overline`, tw`mb-4 text-primary-500 text-opacity-40`]}>
        Navigate To
      </div>
      <div css={tw`flex flex-col divide-y divide-grey divide-opacity-20`}>
        {links &&
          links.map((link, i) => (
            <Link key={link.link} to={link.link} style={tw`py-3`}>
              <span
                css={[
                  tw`text-base text-primary-500 text-opacity-40`,
                  activeIndex === i && tw`font-bold text-opacity-100`,
                ]}
              >
                {link.label}
              </span>
            </Link>
          ))}
      </div>
    </aside>
  )
}

SideMenu.propTypes = {
  links: PropTypes.arrayOf(LinkType),
  activeIndex: PropTypes.number,
  style: StyleType,
}

const LegalContent = ({ title, content, date, style }) => {
  const startTag = '<p><strong>'
  const endTag = '</strong></p>'

  const decode = (str) => str.replace(/&#x26;/g, '&')

  const sections = useMemo(
    () =>
      (getHtml(content) || []).split(/(?=<p><strong>)/g).map((section, i) => {
        const endIndex = section.indexOf(endTag) + endTag.length
        const header = section.substring(0, endIndex).replace(startTag, '').replace(endTag, '')
        const id = `section-${i}`
        const tail = section.substring(endIndex + 1)
        return { header, content: `<p id="${id}"><strong>${header}</p></strong>${tail}` }
      }),
    [content]
  )

  const anchors = useMemo(
    () =>
      sections.map((section, i) => ({
        label: decode(section.header),
        link: `#section-${i}`,
      })),
    [sections]
  )

  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <article css={[global`layout.container`, global`layout.grid`, tw`relative`, style]}>
      <div css={tw`col-span-4 md:col-span-8 lg:col-span-10`}>
        <Heading content={title} headingType="h3" style={tw`font-bold text-primary-500`} />
        <span
          css={[
            global`typography.subtitle`,
            tw`block text-primary-500 text-opacity-47 font-bold mt-3 mb-16 lg:(mt-6 mb-24)`,
          ]}
        >
          {format(date, 'MMMM yyyy')}
        </span>
        {sections.map((section, i) => (
          <InView
            as="div"
            rootMargin="-128px 0% -80% 0%"
            onChange={(view) => {
              if (view) setActiveIndex(i)
            }}
            key={i}
            css={[typography, tw`mb-12`, listStyle]}
            dangerouslySetInnerHTML={{ __html: section.content }}
          />
        ))}
      </div>
      <SideMenu
        style={tw`hidden xl:(flex sticky top-32 self-start col-start-12 col-end-15)`}
        links={anchors}
        activeIndex={activeIndex}
      />
    </article>
  )
}

LegalContent.propTypes = {
  title: HTMLType,
  content: HTMLType,
  date: PropTypes.string,
  style: StyleType,
}

export default LegalContent
